<template>
  <!-- 会员中心 -->
  <div class="memberManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="isShowDialog = true">
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :operation-button="operationButton" :loading="loading" />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <add-business-dialog :is-show-dialog="isShowDialog" @handleCancel="handleCancel" @getdata="getdata" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { getBussinessManageList, stopBusinessInfo } from '@/api/memberManagement'
import addBusinessDialog from './children/addBusinessDialog.vue'
export default {
  components: { FormSearch, Table, Pagination, addBusinessDialog },
  data() {
    return {
      isShowDialog: false,
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'approveAdvise',
          rules: 'none'
        }
      ],
      formInline: {
        coreCmpName: '', // 核心企业名称
        state: '', // 是否有效
        groupState: '', // 是否集团模式
        interstType: '', // 付息方式
        pageSize: 10,
        pageNum: 1
      },
      memberInformation: {},
      formItemArr: [
        { type: 'input', label: '公司名称', value: 'coreCmpName' },
        { type: 'select', label: '是否有效', value: 'state', pLabel: 'name', pValue: 'code', child: [{ name: '有效', code: '1' }, { name: '无效', code: '0' }] },
        { type: 'select', label: '是否集团模式', value: 'groupState', pLabel: 'name', pValue: 'code', child: [{ name: '是', code: '1' }, { name: '否', code: '0' }] },
        { type: 'select', label: '付息方式', value: 'interstType', pLabel: 'name', pValue: 'code', child: [{ name: '买方', code: 1 }, { name: '卖方', code: 0 }] }
      ],
      itemData: [
        {
          label: '核心企业名称',
          prop: 'coreCmpName',
          width: 180
        },
        {
          label: '资金企业名称',
          prop: 'fundCmpName',
          width: 140
        },
        {
          label: '商圈编码',
          prop: 'fundBusiNo',
          width: 80
        },
        {
          label: '资金方产品编码',
          prop: 'fundProdNo',
          width: 180
        },
        {
          label: '是否有效',
          prop: 'state',
          width: 140,
          child: [{ value: '1', label: '有效' }, { value: '0', label: '无效' }]
        },
        {
          label: '是否集团模式',
          prop: 'groupState',
          width: 140,
          child: [{ value: '1', label: '是' }, { value: '0', label: '否' }]
        },
        {
          label: '付息方式',
          prop: 'interstType',
          width: 180,
          child: [{ value: '1', label: '买方' }, { value: '0', label: '卖方' }]
        },
        {
          label: '版本号',
          prop: 'bankVersion',
          width: 140
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', withParameters: '详情', label: '详情', handleEvent: this.viewDetail },
        { bType: 'primary', withParameters: '作废', label: '作废', handleEvent: this.handleInvalidate }
      ],
      total: 0,
      loading: false
    }
  },
  methods: {
    getdata(params) {
      this.loading = true
      this.formInline = {
        ...this.formInline,
        ...params
      }
      getBussinessManageList(this.formInline, res => {
        this.loading = false
        this.listData = res.data.list
        this.total = res.data.total
      }, () => {
        this.loading = false
      })
    },
    viewDetail({ id }) {
      this.$router.push({
        path: '/memberManagement/businessManage/detail',
        query: { id }
      })
    },
    handleCancel(flag) {
      this.isShowDialog = flag
    },
    handleInvalidate(rows) {
      this.$confirm(`是否确认作废？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          stopBusinessInfo(rows.id, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '作废成功'
              })
              this.getdata()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style scoped>
.electronicMaterial {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.electronicMaterial img {
  height: 120px;
  width: 180px;
  border-radius: 2px;
}

.electronicMaterial a {
  color: rgb(26, 7, 136);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.el-tabs>>>.el-tabs__content {
  padding: 0 16px 32px;
}
</style>
